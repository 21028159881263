import Layout from "@/layout/Container.vue"

export const commonRouter = [
  {
    path: '/',
    redirect: '/company/account'
  },
  {
    path: '/login',
    name: 'Login',
    meta: {
      title: "登录",
    },
    component: () => import("@/views/login/index")
  },
  // {
  //   path: '/home',
  //   name: 'Home',
  //   meta: {
  //     title: "工作台"
  //   },
  //   component: Layout,
  //   children: [
  //     {
  //       path: '/home/index',
  //       name: 'HomeIndex',
  //       meta: {
  //         title: '数据总览',
  //         parent: '工作台'
  //       },
  //       component: () => import("@/views/home/index")
  //     },
      
  //   ]
  // },
  {
    path: '/company',
    name: 'Company',
    meta: {
      title: "机构组织",
    },
    component: Layout,
    children: [
      {
        path: '/company/organ',
        name: 'CompanyOrgan',
        meta: {
          title: '机构列表',
          parent: '机构组织'
        },
        component: () => import("@/views/company/organ/index")
      },
      {
        path: '/company/account',
        name: 'CompanyAccount',
        meta: {
          title: '账号列表',
          parent: '机构组织'
        },
        component: () => import("@/views/company/account/index")
      },
      {
        path: '/company/gov',
        name: 'CompanyGov',
        meta: {
          title: '政府列表',
          parent: '机构组织'
        },
        component: () => import("@/views/gov/index")
      },
      {
        path: '/company/complain',
        name: 'CompanyComplain',
        meta: {
          title: '投诉机构',
          parent: '机构组织'
        },
        component: () => import("@/views/company/complain/index")
      },
      {
        path: '/company/feedback',
        name: 'CompanyFeedback',
        meta: {
          title: '意见反馈',
          parent: '机构组织'
        },
        component: () => import("@/views/company/feedback/index")
      },
    ]
  },
  {
    path: '/product',
    name: 'Product',
    meta: {
      title: "产品管理",
    },
    component: Layout,
    children: [
      {
        path: '/product/goods',
        name: 'ProductGoods',
        meta: {
          title: '产品列表',
          parent: '产品管理'
        },
        component: () => import("@/views/product/goods/index")
      },
      {
        path: '/product/score',
        name: 'ProductScore',
        meta: {
          title: '产品评价',
          parent: '产品管理'
        },
        component: () => import("@/views/product/score/index")
      },
      {
        path: '/product/mien',
        name: 'ProductMien',
        meta: {
          title: '研学风采',
          parent: '产品管理'
        },
        component: () => import("@/views/product/mien/index")
      },
      {
        path: '/product/tag',
        name: 'ProductTag',
        meta: {
          title: '产品标签',
          parent: '产品管理'
        },
        component: () => import("@/views/product/tag/index")
      },
      {
        path: '/banner/index',
        name: 'BannerIndex',
        meta: {
          title: '轮播图',
          parent: '产品管理'
        },
        component: () => import("@/views/banner/index")
      },
    ]
  },
  {
    path: '/order',
    name: 'Order',
    meta: {
      title: "订单管理",
    },
    component: Layout,
    children: [
      {
        path: '/order/list',
        name: 'OrderList',
        meta: {
          title: '订单列表',
          parent: '订单管理'
        },
        component: () => import("@/views/order/index")
      },
    ]
  },
  {
    path: '/settle',
    name: 'Settle',
    meta: {
      title: "结算管理",
    },
    component: Layout,
    children: [
      {
        path: '/settle/list',
        name: 'SettleList',
        meta: {
          title: '提现审核',
          parent: '结算管理'
        },
        component: () => import("@/views/settle/list/index")
      },
      {
        path: '/settle/profit',
        name: 'SettleProfit',
        meta: {
          title: '分佣收益',
          parent: '结算管理'
        },
        component: () => import("@/views/settle/profit/index")
      },
    ]
  },
  {
    path: '/school',
    name: 'School',
    meta: {
      title: "学校管理",
    },
    component: Layout,
    children: [
      {
        path: '/school/list',
        name: 'SchoolList',
        meta: {
          title: '学校列表',
          parent: '学校管理'
        },
        component: () => import("@/views/school/index")
      }
    ]
  },
  
  
  {
    path: '*',
    name: '404',
    meta: {
      title: '404',
    },
    component: () => import("@/views/404")
  }
]