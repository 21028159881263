<template>
  <el-container class="layout__wrap">
    <el-header class="header__wrap" height="68px">
      <div class="logo__wrap">
        <img class="logo" src="@/assets/img/logo2.png" alt="">
        <div class="J_menutoggle" @click="toggleColl">
          <el-tooltip
            effect="dark"
            :content="isCollapse ? `展开` : `收起`"
            placement="bottom">
            <i class="el-icon-menu" color="#fff"></i>
          </el-tooltip>
        </div>
      </div>
      <div class="avatar__wrap">
        <div style="display:flex;align-items:center;">
          <img class="imageA" style="margin-left:50px;" src="@/assets/img/default.png">
          <span>{{userName}}</span>
        </div>

        <el-dropdown @command="handleCommand">
          <i class="el-icon-caret-bottom" style="color:#fff;padding:10px;"></i>
          <el-dropdown-menu slot="dropdown">
            <!-- <el-dropdown-item command="pass">修改密码</el-dropdown-item> -->
            <el-dropdown-item command="logout" divided @click="logout">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </el-header>
    
    <el-container style="overflow-y:scroll;">
      <NaviBar :isCollapse="isCollapse"></NaviBar>
      <el-main class="layout__main">
        <page-header></page-header>
        <router-view></router-view>
      </el-main>
    </el-container>

    <!-- 修改密码 -->
    <el-dialog
      append-to-body
      width="500px"
      title=""
      :visible.sync="visible"
      :before-close="reset"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <template #title>
        <div class="el-dialog-title">修改密码</div>
      </template>

      <el-form
        v-loading="loading"
        class="saft_form"
        element-loading-text="加载中…"
        ref="elFormDom"
        label-width="130px"
        label-position="top"
        :model="form.data"
        :rules="form.rules"
        size="medium"
        hide-required-asterisk
      >
        <el-form-item label="新密码" prop="password">
          <el-input type="password" v-model="form.data.password" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="checkPass">
          <el-input type="password" v-model="form.data.checkPass" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="visible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 消息列表 -->
    <el-dialog
      append-to-body
      width="600px"
      title=""
      :visible.sync="dialogVisible"
      :before-close="reset"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
    >
      <template #title>
        <div class="el-dialog-title">系统通知</div>
      </template>
      <div>
        <div>认证通过</div>
        <div>欢迎您入驻劳研研学平台，您的机构信息已认证通过，欢迎使用</div>
        <div>2023-09-04 16:29:49</div>
      </div>
      <div>
        <div>认证通过</div>
        <div>欢迎您入驻劳研研学平台，您的机构信息已认证通过，欢迎使用</div>
        <div>2023-09-04 16:29:49</div>
      </div>
    </el-dialog>


  </el-container>
</template>
<script>
import PageHeader from './PageHeader.vue';
import NaviBar from './NaviBar';
import { getStorage, clear } from "@/storage"
import drawMixin from "@/mixins/drawMixin";
export default {
  name: 'LayoutContainer',
  mixins: [drawMixin],
  components: {
    NaviBar,
    PageHeader
  },
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入新密码'));
      } else {
        if (this.form.data.checkPass !== '') {
          this.$refs.elFormDom.validateField('checkPass');
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.form.data.password) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      visible: false,
      loading: false,
      isCollapse: false,
      toggleMenu: false,
      userName: getStorage('userName') || 'admin',
      form: {
        login: false,
        data: {
          password: ''
        },
        rules: {
          password: [
            { required: true, validator: validatePass, trigger: 'blur' }
          ],
          checkPass: [
            { required: true, validator: validatePass2, trigger: 'blur' }
          ],
        }
      },
      un_read: 0, //未读：0-没有未读消息；1-有未读消息；
      msgList: [],
      dialogVisible: false,
    }
  },
 
  created() {
    this.getCompany();
  },
  methods: {
    // 所属机构
    getCompany() {
      this.$http.get('/admin/company/list', {params: {status:2,page:1,count:10000}}).then(res => {
        if(res.code == 1){
          this.$store.commit("setCompanyArr", res.data.list);
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    handleCommand(command) {
      if(command === 'logout') {
        this.logout();
      } else {
        this.visible = true;
      }
    },
    // 退出登录
    logout() {
      this.$confirm("请确认是否退出登录？", "提示", {
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            clear(); // 删除token
            window.location.assign('/login')
            done()
          } else {
            done()
          }
        }
      }).catch(err => {})
    },
    // 修改密码
    submitForm() {
      this.$refs.elFormDom.validate(valid => {
        if(valid) {
          this.$http.post('/admin/index/updatePassword', {password: this.form.data.password}).then(res => {
            if(res.code === 1) {
              this.$message.success('操作成功！')
              this.visible = false;
            } else {
              this.$message.error(res.msg)
            }
          })
        }
      })
    },
    // 展开、收起
    toggleColl() {
      this.isCollapse = !this.isCollapse;
      this.toggleMenu = !this.toggleMenu;
    },
    reset() {
      this.visible = false;
      this.dialogVisible = false;
      this.$refs.elFormDom.validateField();
    },
    toStr(data,key){
      return data[key]
    }
  }
}
</script>

<style scoped lang="scss">
.layout__wrap {
  width: 100%;
  height: 100vh;
  background-color: #f5f5f5;
  
  .header__wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $theme;
    // background-image: linear-gradient(to right, $theme, #0bCFFF);
    .logo__wrap {
      display: flex;
      align-items: center;
      .logo {
        width: 129px;
        height: 30px;
      }
      .logoText {
        color: #fff;
        font-size: 20px;
        font-weight: bold;
      }
      .J_menutoggle {
        color: #fff;
        margin-left: 20px;
        display: none;
      }
    }
    .avatar__wrap {
      color: #fff;
      display: flex;
      align-items: center;
      
      .userName {
        margin-right: 20px;
      }
      .exit {
        cursor: pointer;
        display: flex;
        align-items: center;
      }
      .item {
        text-align: center;
        width: 100%;
        cursor: pointer;
      }
    }
  }
  .layout__main {
    display: flex;
    flex-direction: column;
    padding: 0;
    background: #F2F5FC;
    position: relative;
    // transition: width .3s ease-in-out;

    .page-header__wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #FFFFFF;
      color: #595959;

      .title {
        height: 58px;
        line-height: 58px;
        
      }
    }

    &>div {
      flex: 1;
      min-height: 0;
      overflow: hidden;
    }

  }
  .imageA {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
  }
}


</style>
