import Vue from 'vue'
import Vuex from 'vuex'
// import http from "@/api/http"
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    unit: [],
    kinds: [],
    settleType: {
      1:'日结',
      2:'项目结',
      3:'可议'
    },
    routerList: [],
    companyArr: [],
  },
  mutations: {
    setUnit: (state, payload) => state.unit = payload,
    setKinds: (state, payload) => state.kinds = payload,
    setRouter: (state, payload) => state.routerList = payload,
    setCompanyArr: (state, payload) => state.companyArr = payload,
  }, 
  actions: {
    setRouter({commit}, router) {
      commit('setRouter', router)
    }
  },
  getters: {
    setUnit: state => state.unit,
    setKinds: state => state.kinds,
    setRouter: state => state.routerList,
    companyArr: state => state.companyArr,
  },
  modules: {
  },
})
